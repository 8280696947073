const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getPath = (path) => {
  return `${API_BASE_URL}${path}`;
};

const endpoint = {
  tokenGet: getPath('/getToken'), // 토큰 발급
  token: getPath('/verifyRivToken') // 토큰 검증
};

export default endpoint;
