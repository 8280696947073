import styled from '@emotion/styled';
import styles from 'src/assets/app.module.css';
import Header from '../components/Header';
import Button from '../components/Button';
import ButtonGroup from '../components/ButtonGroup';
import Info_img from 'src/assets/icon/ico_info.svg';

const ErrorMsg = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-size: var(--body2-size);
  line-height: 150%;
  font-family: var(--body2);
  color: var(--text-subdued);

  & p {
    font-size: var(--title4-size);
    line-height: 150%;
    font-family: var(--title4);
    color: var(--text-point);
    margin-bottom: 5px;
  }
`;

const Page404 = () => {
  return (
    <div className={styles.wrap}>
      <Header step="step5" />

      <div className={styles.ContWrap}>
        <ErrorMsg>
          <img src={Info_img} className={styles.info_img} alt="React" />
          <p>
            죄송합니다.
            <br />
            요청하신 페이지를 찾을 수 없습니다.
          </p>
          찾으시는 주소가 잘못 입력되었거나
          <br />
          변경 혹은 삭제되어 주소를 사용할 수 없어요.
          <br />
          입력하신 페이지의 주소를 다시 한번
          <br />
          확인해주세요
        </ErrorMsg>
      </div>

      <ButtonGroup gap="true" main>
        <Button text="홈으로 가기" fill="primary" size="large" status="active" />
      </ButtonGroup>
    </div>
  );
};

export default Page404;
