import styled from '@emotion/styled';

const StyledTextTitleForm = styled.div``;

const StyledTitle = styled.h1`
  margin: 0;
  font-size: ${({ size }) => {
    switch (size) {
      case 'xsmallsub':
        return '16px';
      case 'xsmall':
        return '16px';
      case 'smallsub':
        return '18px';
      case 'small':
        return '18px';
      case 'medium':
        return '20px';
      case 'large':
        return '22px';
      default:
        return '26px';
    }
  }};
  line-height: 1.5;
  font-family: HanwhaGothic;
  color: #272b2f;
  text-align: left;
  display: inline-block;
`;

const StyledDesc = styled.div`
  margin-top: 8px;
  font-size: 16px;
  line-height: 1.5;
  font-family: HanwhaGothic;
  color: #737c85;
  text-align: left;
  white-space: pre-line;
`;

function TextTitle({ size, type, title, description }) {
  return (
    <StyledTextTitleForm type={type}>
      <StyledTitle size={size}>{title}</StyledTitle>
      <StyledDesc>{description}</StyledDesc>
    </StyledTextTitleForm>
  );
}

export default TextTitle;
