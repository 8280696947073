import { OsType, BrowserType, CameraType } from '../modules/scanner/scanner_constants.js';

export function execFuncIfExists(obj, funcName, ...args) {
  if (obj && typeof obj === 'object' && typeof obj[funcName] === 'function') {
    return obj[funcName](...args);
  }
  return null;
}

export function loadScript(document, url) {
  return new Promise((resolve, reject) => {
    if (!(document instanceof Document)) {
      reject('document is not instance of Document');
      return;
    }

    checkIfExists(url)
      .then(() => {
        let script = document.createElement('script');
        script.type = 'text/javascript';

        if (script.readyState) {
          // IE
          script.onreadystatechange = function () {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              document.body.appendChild(script);
              resolve();
            } else {
              reject('failed to load script, path: ' + url);
              return;
            }
          };
        } else {
          // Others
          script.onload = function () {
            document.body.appendChild(script);
            resolve();
          };
          script.onerror = function () {
            reject('failed to load script, path: ' + url);
            return;
          };
        }

        script.src = url;
        document.body.appendChild(script);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function loadScripts(urls) {
  let scripts = [];
  urls.forEach((url) => {
    scripts.push(loadScript(document, url));
  });
  return Promise.all(scripts);
}

export async function loadJSON(path) {
  try {
    return await fetch(path).then((res) => res.json());
  } catch (error) {
    throw error;
  }
}

export function str2ab(str) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

export function getCameraType(browserInfo) {
  let cameraType = CameraType.FACING_UNKNOWN;
  if (browserInfo.hasOwnProperty('OsType')) {
    if (browserInfo.OsType === OsType.IOS || browserInfo.OsType === OsType.ANDROID) {
      cameraType = CameraType.FACING_BACK;
    }
  }

  return cameraType;
}

export function getUrlParams() {
  let params = {};
  window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, (str, key, value) => {
    params[key] = value;
  });

  return params;
}

/**
 * 디바이스가 iOS인 경우, iOS의 버전을 반환
 */
export function getIosVersion() {
  let userAgent = navigator.userAgent;
  let match = userAgent.match(/(iPhone|iPod|iPad).*OS\s([\d_]+)/);

  if (!match) return;

  let version = match[2].replace(/_/g, '.');
  return version;
}

/**
 * 디바이스가 iOS인지 체크
 */
export function isIosDevice() {
  let userAgent = navigator.userAgent;
  let isIOS = /iPad|iPhone|iPod/.test(userAgent);
  return isIOS;
}

/**
 * 디바이스가 Android인지 체크
 */
export function isAndroidDevice() {
  let userAgent = navigator.userAgent;
  let isAndroid = /Android/.test(userAgent);
  return isAndroid;
}

export function convStream2String(buffer, length, b64 = false) {
  if (!buffer) {
    return '';
  }
  if (length <= 0) {
    return '';
  }

  let binaryString = [length];
  while (length--) {
    binaryString[length] = String.fromCharCode(buffer[length]);
  }

  // return binaryString.join("");
  binaryString = binaryString.join('');

  if (b64) {
    binaryString = window.btoa(binaryString);
  }

  return binaryString;
}

export function deepCopy(obj, hash = new WeakMap()) {
  if (Object(obj) !== obj) {
    return obj;
  }
  if (hash.has(obj)) {
    return hash.get(obj);
  }

  const result = Array.isArray(obj) ? [] : obj.constructor ? new obj.constructor() : Object.create(null);
  hash.set(obj, result);
  return Object.assign(result, ...Object.keys(obj).map((key) => ({ [key]: deepCopy(obj[key], hash) })));
}

export function base64ToBlob(base64, contentType = 'application/octet-stream', sliceSize = 512) {
  let byteCharacters = atob(base64);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  let blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function checkIfExists(url) {
  return new Promise(async (resolve, reject) => {
    let exists = false;
    try {
      const response = await fetch(url, {
        method: 'HEAD',
        cache: 'no-store'
      });

      if (response.ok) {
        exists = true;
      }
    } catch (error) {}

    if (exists) {
      resolve();
    } else {
      reject(`error fetching the file. url: ${url}`);
    }
  });
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function getDisplaySize() {
  let ratio = window.devicePixelRatio;
  let w = Math.round((window.screen.width * ratio) / 10) * 10;
  let h = Math.round((window.screen.height * ratio) / 10) * 10;
  return {
    width: w,
    height: h
  };
}

export function base64ToArrayBuffer(base64) {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

export function arrayBufferToBase64(buffer) {
  let length = buffer.byteLength;
  let view;

  if (view instanceof Uint8Array) {
    view = buffer;
  } else {
    view = new Uint8Array(buffer);
  }

  let binaryString = [length];
  while (length--) {
    binaryString[length] = String.fromCharCode(view[length]);
  }

  const binary = binaryString.join('');
  return btoa(binary);
}

export function clearBuffer(buffer) {
  if (!buffer) {
    return;
  }
  if (!buffer instanceof ArrayBuffer) {
    return;
  }

  let tmp = new Uint8Array(buffer);
  for (let i = 0; i < tmp.length; i++) {
    tmp[i] = 0;
  }
}

export function parseBrowserInfo() {
  let ua = navigator.userAgent;
  const UNKNOWN = 'unknown';
  let info = {
    browserType: BrowserType.UNKNOWN,
    browserVersion: UNKNOWN,
    osName: OsType.UNKNOWN,
    osVersion: UNKNOWN,
    deviceId: UNKNOWN
  };

  let detailParseChain = Promise.resolve(info);

  if (/(iphone|ipad)+/i.test(ua)) {
    // check iOS safari
    info.osName = OsType.IOS;
    info.browserType = BrowserType.SAFARI;
    var apple_version = ua.match(/version\/(\d+)/i);
    if (apple_version != null) {
      info.osVersion = apple_version[1];
    } else {
      var M1 = ua.match(/(iphone os|iphoneos|cpu os|cpuso|(?=\s))\/?\s*(\d+)/i) || [];
      // apple device 는 cpu정보로 확인한다.
      if (M1 != null) {
        info.osVersion = M1[2];
      }
    }
  } else {
    if (navigator.userAgentData && navigator.userAgentData.getHighEntropyValues) {
      detailParseChain = navigator.userAgentData
        .getHighEntropyValues(['model', 'fullVersionList'])
        .then((detail) => {
          info.deviceId = detail.model;
          info.osName = detail.platform;
          info.osVersion = detail.platformVersion;

          let brandName = '';
          if (/edga?/i.test(ua)) {
            // check edge
            brandName = 'Edge';
            info.browserType = BrowserType.EDGE;
          } else if (/samsung/i.test(ua)) {
            brandName = 'Samsung';
            info.browserType = BrowserType.SAMSUNG_BROWSER;
          } else if (/chrome/i.test(ua)) {
            brandName = 'Chrome';
            info.browserType = BrowserType.CHROME;
          }

          let brandFullVer = detail.fullVersionList.find((element) => element.brand.includes(brandName));
          if (brandFullVer) {
            info.browserVersion = brandFullVer.version;
          }
          return info;
        })
        .catch((error) => {
          this.logger.error(`failed to parse browser information using userAgentData: ${error}`);
          return info;
        });
    } else {
      if (/android+/i.test(ua)) {
        info.osName = OsType.ANDROID;
        let regex = /Android\s+([\d.]+).*?;\s*([^;)]+).*\)/i;
        let match = ua.match(regex);
        if (match) {
          info.osVersion = match[1];
          info.deviceId = match[2];
        }
      } else if (/Win(dows)?/i.test(ua)) {
        info.osName = OsType.WINDOWS;
        // Windows not support officially
      } else if (/Mac|PPC/i.test(ua)) {
        info.osName = OsType.MAC;
        // MAC not support officially
      }

      if (/edga?/i.test(ua)) {
        // check edge browser
        info.browserType = BrowserType.EDGE;
        let browserMatch = ua.match(/EdgA?\/(\b\d+(?:\.\d+)*\b)/i) || [];
        if (browserMatch != null) {
          info.browserVersion = browserMatch[1];
        }
      } else if (/samsung/i.test(ua)) {
        // check samsung browser
        info.browserType = BrowserType.SAMSUNG_BROWSER;
        let browserMatch = ua.match(/SamsungBrowser\/(\b\d+(?:\.\d+)*\b)/i) || [];
        if (browserMatch != null) {
          info.browserVersion = browserMatch[1];
        }
      } else if (/chrome?/i.test(ua)) {
        info.browserType = BrowserType.CHROME;
        let browserMatch = ua.match(/Chrome\/(\b\d+(?:\.\d+)*\b)/i) || [];
        if (browserMatch != null) {
          info.browserVersion = browserMatch[1];
        }
      }
    }
  }

  return detailParseChain;
}

export function convertUnixtime2Datetime(convertTime) {
  let unixTimestamp = convertTime;

  // Convert to milliseconds and
  // then create a new Date object
  let dateObj = new Date(unixTimestamp * 1000);
  let utcString = dateObj.toUTCString();

  let time = utcString.slice(-11, -4);
  return time;
}
