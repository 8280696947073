import { atom } from 'recoil';

export const ocrState = atom({
  key: 'ocrState',
  default: {}
});

export const popupState = atom({
  key: 'popupState',
  default: false
});
