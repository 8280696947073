import React, { useEffect } from 'react';
import Header from 'src/components/Header';
import styles from 'src/assets/app.module.css';
import finish from 'src/assets/illustrator/finish.svg';
import TextTitle from 'src/components/TextTitle';
import ButtonGroup from 'src/components/ButtonGroup';
import Button from 'src/components/Button';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import { ocrState } from './state';
import { RivConstant } from 'src/constants/rivConstant';

const ImgBox = styled.div`
  text-align: center;
  margin-top: 110px;
  canvas {
    max-width: 100%;
  }
`;

export default function Step6() {
  const navigate = useNavigate();
  const [resultData, setResultData] = useRecoilState(ocrState);

  // 취소 버튼 클릭이벤트
  const handleOKClick = () => {
    sessionStorage.removeItem(RivConstant.ACCESS_TOKEN_KEY);
    setResultData({});

    window.close();
    navigate('/');
  };

  useEffect(() => {
    setResultData({});
  }, [setResultData]);

  return (
    <>
      <Header step="step6" />
      <div className={styles.ContWrap}>
        <TextTitle
          size="xlarge"
          type="title"
          title="등록 완료"
          description={`본인확인을 위한 모든 절차가 완료되었습니다.`}
        />

        <ImgBox>
          <img src={finish} className={styles.step2_img} alt="비대면 인증 완료 이미지" />
        </ImgBox>
      </div>

      <ButtonGroup gap="true" main>
        <Button text="확인" fill="primary" size="large" status="active" onClick={handleOKClick} />
      </ButtonGroup>
    </>
  );
}
