import styled from '@emotion/styled';

const Layout01 = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  column-gap: ${({ gap }) => (gap === 'true' ? '8px' : '0')};
  padding: ${({ main }) => (main ? '32px 20px 20px' : '0')};
`;

function ButtonGroup({ children, gap, main }) {
  return (
    <Layout01 gap={gap} main={main}>
      {children}
    </Layout01>
  );
}

export default ButtonGroup;
