import styled from '@emotion/styled';
import Button from '../components/Button';
import ButtonGroup from '../components/ButtonGroup';

const Title = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
  font-size: var(--h3-size);
  line-height: 150%;
  font-family: var(--h3);
  color: var(--text-primary);
  text-align: left;
  display: inline-block;
`;
const Top = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--spacing-150) 0px var(--spacing-300);
`;
const Text = styled.div`
  position: relative;
  font-size: var(--body2-size);
  line-height: 150%;
  font-family: var(--body2);
  color: var(--text-subdued);
  text-align: left;
  display: inline-block;
  max-width: 280px;
`;
const Body = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px var(--spacing-250);
  font-size: var(--body2-size);
  color: var(--text-subdued);
`;

const DialogBox = styled.div`
  position: absolute;
  border-radius: var(--radius-xl);
  background-color: var(--bg-primary);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--spacing-250);
  box-sizing: border-box;
  max-height: 400px;
  text-align: left;
  font-size: var(--h3-size);
  color: var(--text-primary);
  font-family: var(--subtitle2);
  top: 50%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
`;

const LayerPopup = styled.div`
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: var(--bg-dimmed);
  z-index: 4;
`;

function Dialog({ title, description, buttonContents }) {
  return (
    <LayerPopup>
      <DialogBox>
        <Top>
          <Title>{title}</Title>
        </Top>
        <Body>
          <Text>{description}</Text>
        </Body>

        <ButtonGroup gap="true" dialog>
          {buttonContents.map((item, index) => (
            <Button
              key={`item-${index}`}
              type={item.type}
              text={item.text}
              fill={item.fill}
              size="large"
              status="active"
              onClick={item.onClick}
            />
          ))}
        </ButtonGroup>
      </DialogBox>
    </LayerPopup>
  );
}

export default Dialog;
