// react
import { React, useEffect, useState } from 'react';

// apis
import { getTokenApi, validateTokenApi } from 'src/apis/apis';

// libraries
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

// components
import Header from 'src/components/Header';
import TextTitle from 'src/components/TextTitle';
import ButtonGroup from 'src/components/ButtonGroup';
import Button from 'src/components/Button';

// assets
import styles from 'src/assets/app.module.css';
import girlIllustrator from 'src/assets/illustrator/girl-sitting.svg';
import Dialog from 'src/components/Dialog';
import { RivConstant } from 'src/constants/rivConstant';

export default function Step1() {
  const navigate = useNavigate();
  // state
  const [searchParams] = useSearchParams();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  /**-------------------------------- useEffect --------------------------------------*/
  useEffect(() => {
    const initializeToken = async () => {
      // Query String 토큰
      const queryToken = searchParams.get('authorization');
      // 다른페이지에서 홈으로 오는 경우 session token 사용
      const sessionToken = sessionStorage.getItem(RivConstant.ACCESS_TOKEN_KEY);

      if (queryToken === 'test') {
        const token = await getToken();
        await validateToken(token);
      } else if (queryToken) {
        await validateToken(queryToken);
      } else if (!queryToken && sessionToken) {
        await validateToken(sessionToken);
      } else {
        setOpenModal(true);
        console.error('===== [useEffect []] ===== : QueryString 토큰 추출 Fail.');
      }
    };
    initializeToken();
  }, []);

  /**-------------------------------- apis --------------------------------------*/
  /*토큰 발급 API 호출 : 임시*/
  const getToken = async () => {
    const data = {
      header: {},
      payload: {
        custId: '4063705844'
      }
    };
    const response = await getTokenApi(data);

    if (response.header.rsltCode === '0000') {
      return response.payload.accessToken;
    } else {
      console.error('===== [Token Create API Fail] ===== rsltMsgeCntn: ', data.header.rsltMsgeCntn);

      return '';
    }
  };

  /*토큰 검증 API 호출*/
  const validateToken = async (token) => {
    const reqData = {
      token
    };
    try {
      setLoading(true);
      const response = await validateTokenApi(reqData);
      setLoading(false);

      if (response.header.rsltCode === '0000') {
        sessionStorage.setItem(RivConstant.ACCESS_TOKEN_KEY, token);
      } else {
        console.error('===== [Token Validation API rsltCode] ===== rsltMsgeCntn: ', data.header.rsltMsgeCntn);
        setOpenModal(true);
      }
    } catch (e) {
      console.error('===== [isError] ===== error: ', e);
      setOpenModal(true);
    }
  };

  /**-------------------------------- 이벤트 헨들러 --------------------------------------*/
  /*시작하기 클릭이벤트*/
  const handleClickStart = () => {
    navigate('/step2');
  };

  /*토큰에러모달 닫기 버튼 클릭이벤트*/
  const handleModalClose = () => {
    // clear data
    sessionStorage.removeItem(RivConstant.ACCESS_TOKEN_KEY);

    setOpenModal(false);
    window.close();
  };

  return (
    <>
      <Header />
      <div className={styles.ContWrap}>
        <TextTitle
          size="xlarge"
          type="title"
          title="비대면 인증"
          description={`스마트폰만 있으면, 언제, \n어디서나 간편하게 본인 인증이 가능합니다. \n더욱 스마트한 생활을 누려보세요. \n\n한화생명이 여러분을 도와드립니다.`}
        />

        <img src={girlIllustrator} className={styles.step1_img} alt="React" />
      </div>

      <ButtonGroup gap="true" main>
        <Button text="시작하기" fill="primary" size="large" status="active" onClick={handleClickStart} />
      </ButtonGroup>
      {openModal && (
        <Dialog
          title="토큰 에러 발생"
          description="에러가 발생했습니다. 다시 시도해주세요."
          buttonContents={[
            {
              text: '확인',
              fill: 'primary',
              onClick: handleModalClose
            }
          ]}
        />
      )}
    </>
  );
}
