import { useEffect, useState, useRef } from 'react';
import Header from 'src/components/Header';
import TextTitle from 'src/components/TextTitle';
import styles from 'src/assets/app.module.css';
import styled from '@emotion/styled';
import ButtonGroup from 'src/components/ButtonGroup';
import Button from 'src/components/Button';
import FieldSet from 'src/components/FieldSet';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ocrState } from './state';
import { useNavigate, useLocation } from 'react-router';
import { showResultImage } from 'src/modules/view';
import axiosInstance from 'src/apis/axios';
import Dialog from 'src/components/Dialog';
import { RivConstant } from 'src/constants/rivConstant';
import CryptoJS from 'crypto-js';

const ImgBox = styled.div`
  text-align: center;
  margin-top: 30px;
  canvas {
    max-width: 100%;
  }
`;

const FieldSetBox = styled.div``;

const LayerPopup = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90vw;
  transform: translate(-50%, -50%);
  z-index: 4;
`;

export default function Step5() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const ocrValue = useRecoilValue(ocrState);
  const [resultData, setResultData] = useRecoilState(ocrState);
  const [display, setDisplay] = useState(false);
  const isDisableAPI = useRef(false);
  const [openModal, setOpenModal] = useState(false);

  const AES_IV = `${process.env.REACT_APP_API_IV}`;
  const aes256Key = `${process.env.REACT_APP_API_KEY}`.padEnd(16, '\0');

  const aesDecrypt = (str) => {
    let dataDecrypt = str;

    try {
      if (str !== null && str !== '') {
        const encryptedBytes = CryptoJS.enc.Base64.parse(dataDecrypt);

        const keyBytes = CryptoJS.enc.Utf8.parse(aes256Key);
        const ivBytes = CryptoJS.enc.Hex.parse(AES_IV);

        const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext: encryptedBytes }, keyBytes, {
          iv: ivBytes,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        });

        const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

        return decryptedText;
      }
    } catch (error) {
      console.error('AES 복호화 에러:', error.message);
    }

    return null;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      setResultData({});
    });
    window.addEventListener('unload', () => {
      setResultData({});
    });

    return () => {
      window.removeEventListener('beforeunload', () => {
        setResultData({});
      });
      window.removeEventListener('unload', () => {
        setResultData({});
      });
    };
  }, [setResultData]);

  const goBack = () => {
    setResultData({});
    navigate('/step3');
  };

  const handleComplete = async () => {
    if (isDisableAPI.current) {
      return false;
    }
    isDisableAPI.current = true;
    try {
      const kr = 9 * 60 * 60 * 1000;
      const curDate = new Date(Date.now() + kr).toISOString().split('T')[0].replaceAll('-', '');
      const curTime = new Date().toTimeString().split(' ')[0].replaceAll(':', '');
      const curDay = curDate.slice(-2);
      const dvsnCode = 'IDV';
      const orgnCode = '432';

      let response = await axiosInstance.post(`${API_BASE_URL}/doRmtIdVer`, {
        header: '7419HDR',
        sysDvsnCode: dvsnCode,
        rprsOrgnCode: orgnCode,
        tlgrAscfCode: '0200',
        trnnDvsnCode: resultData.cardType === '1' ? '501100' : '501110',
        sdrcFlag: 'B',
        status: '000',
        rspnCode: '',
        tlgrTrnmDate: curDate,
        tlgrTrnmTme: curTime,
        tlgrTrcgNo: `${curDay}${curTime}`,
        filler1: '',
        trnnUniqNo: `${orgnCode}${dvsnCode}${curDay.substring(1)}${curTime}`,
        idcdDvsn: resultData.cardType === '1' ? '01' : '02',
        altrItmDvsn: '01',
        rlnmNo: resultData.idNumberEnc,
        idcdNm: resultData.name,
        isncDate: resultData.issueDateEnc,
        idcdNo: resultData.driverLicenseNumberEnc ? resultData.driverLicenseNumberEnc : '000000000000',
        vetNo: '000000000',
        brocCode: '4320000',
        bnkIdNo: 'RIV8888888',
        evalScor: resultData.faceExResult < 100 ? `0${resultData.faceExResult}` : resultData.faceExResult,
        faceDvsnCode: '10',
        recgDvsnCode: '',
        filler2: '',
        finFturLngt: '0000',
        phgtInfoLngt: resultData.faceExFeature.length,
        phgtInfo: resultData.faceExFeature
      });

      if (response.status === 200) {
        if (response.data.payload.rspnCode === '000') {
          navigate('/step6');
        } else {
          setDisplay(true);
        }
        response = null;
      } else {
        setDisplay(true);
      }
    } catch (error) {
      console.log(error);
      setDisplay(true);
    } finally {
      isDisableAPI.current = false;
    }
  };

  useEffect(() => {
    if (Object.keys(ocrValue).length < 1) {
      navigate('/step3');
    }

    if (ocrValue) {
      showResultImage(aesDecrypt(ocrValue?.maskedCardImageEnc));
    }
  }, [ocrValue, navigate, aesDecrypt]);

  const goHome = () => {
    setResultData({});
    setDisplay(false);
    navigate('/');
  };

  useEffect(() => {
    const getToken = sessionStorage.getItem(RivConstant.ACCESS_TOKEN_KEY);

    if (!getToken || getToken.trim().length < 1) {
      setOpenModal(true);
    }
  }, []);

  const handleModalClose = () => {
    // clear data
    sessionStorage.removeItem(RivConstant.ACCESS_TOKEN_KEY);
    setResultData({});

    setOpenModal(false);
    navigate('/');
  };

  return (
    <>
      <Header step="step2" />

      <div className={styles.ContWrap}>
        <TextTitle size="xlarge" type="title" title={`운전면허증 정보를 \n확인해 주세요.`} />

        <ImgBox id="resultCanvasWrapper" className="result-canvas-wrap">
          <canvas id="resultCanvas" className="result-canvas"></canvas>
        </ImgBox>

        <FieldSetBox>
          <FieldSet label="성명" value={ocrValue?.name} type="text" placeholder="" />

          {ocrValue?.maskedIdNumberEnc && (
            <FieldSet
              label="주민등록번호"
              value={aesDecrypt(ocrValue?.maskedIdNumberEnc).substring(0, 6)}
              value2={aesDecrypt(ocrValue?.maskedIdNumberEnc).substring(
                6,
                aesDecrypt(ocrValue?.maskedIdNumberEnc).length
              )}
              type="text"
              multi="true"
              placeholder=""
            />
          )}

          {ocrValue?.maskedDriverNumberEnc && (
            <FieldSet label="면허번호" value={aesDecrypt(ocrValue?.maskedDriverNumberEnc)} type="text" placeholder="" />
          )}

          <FieldSet label="발급일자" value={aesDecrypt(ocrValue?.maskedIssueDateEnc)} type="text" placeholder="" />
        </FieldSetBox>
      </div>

      {/* <Accordion
        title="알려드립니다."
        content={['타이틀을 입력하세요', '타이틀을 입력하세요', '타이틀을 입력하세요']}
      /> */}

      <ButtonGroup gap="true" main>
        <Button text="다시 촬영" fill="neutral1" size="large" status="active" onClick={goBack} />
        <Button text="완료" fill="primary" size="large" status="active" onClick={handleComplete} />
      </ButtonGroup>

      {display && (
        <LayerPopup>
          <Dialog
            title="신분증 인식 실패"
            description="원활한 촬영을 위헤 네모 영역 안으로 신분증을 맞춰주세요."
            buttonContents={[
              {
                text: '홈으로',
                fill: 'neutral1',
                onClick: goHome
              },
              {
                text: '재촬영',
                fill: 'primary',
                onClick: goBack
              }
            ]}
          />
        </LayerPopup>
      )}

      {openModal && (
        <Dialog
          title="에러 발생"
          description="에러가 발생했습니다. 다시 시도해주세요."
          buttonContents={[
            {
              text: '확인',
              fill: 'primary',
              onClick: handleModalClose
            }
          ]}
        />
      )}
    </>
  );
}
