// react
import React, { useEffect, useRef, useState } from 'react';
// recoil
import { useRecoilState } from 'recoil';
// libraries
import { useNavigate } from 'react-router';
// scripts
import { ocrState, popupState } from './state';
import { contentsLoaded, scanner } from 'src/scripts/robiscan';
// components
import Dialog from 'src/components/Dialog';
// style
import styled from '@emotion/styled';
// assets
import Mask_bg from 'src/assets/images/mask_bg.svg';
import Mask_img from 'src/assets/images/mask_canvas.svg';
import cameraBtn from 'src/assets/icon/btn-camera.svg';
import { RivConstant } from 'src/constants/rivConstant';
import CryptoJS from 'crypto-js';

const BgRoot = styled.div`
  position: relative;
  height: 100%;
  background-color: var(--bg-dimmed);
`;

const P = styled.p`
  margin: 0;
`;

const TextTitle = styled.div`
  position: relative;
  z-index: 100;
  font-size: 16px;
  line-height: 150%;
  font-family: var(--font-hanwhagothic);
  color: #fff;
  text-align: left;
  display: inline-block;
  max-width: 300px;
  margin: 84px 20px 0;
`;

const CameraVideo = styled.video`
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  mask-image: url(${Mask_bg});
  mask-position: center center;
  mask-size: 100%;
`;

const CameraCanvas = styled.canvas`
  width: 100%;
  height: 100%;
  padding: 10px;
  object-fit: cover;
`;

const ButtonCameraIcon = styled.img`
  width: 100%;
  position: absolute;
  left: 50%;
  bottom: 96px;
  transform: translateX(-50%);
  max-width: 100%;
  overflow: hidden;
  width: 64px;
  height: 64px;
  cursor: pointer;
  z-index: 3;
`;

const OcrResult = styled.section`
  display: none;
`;

const OcrReadFile = styled.input`
  display: none;
`;

const OcrReadReault = styled.div`
  display: none;
`;

const LayerPopup = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90vw;
  transform: translate(-50%, -50%);
  z-index: 4;
`;

const Maskimg = styled.div`
  position: relative;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #fa6600;
    mask-image: url(${Mask_img});
    mask-repeat: no-repeat;
    mask-position: center center;
    mask-size: 90%;
    aspect-ratio: 1;
    object-fit: cover;
  }
`;

export default function Step3() {
  // navigate
  const navigate = useNavigate();
  // recoil
  const [display, setDisplay] = useRecoilState(popupState);
  const [resultData, setResultData] = useRecoilState(ocrState);
  // states
  const [openModal, setOpenModal] = useState(false);
  const [resultMessage, setResultMessage] = useState('원활한 촬영을 위헤 네모 영역 안으로 신분증을 맞춰주세요.');
  // ref
  const childrenRef = useRef(null);

  useEffect(() => {
    // OCR 통신 CALL
    contentsLoaded();

    window.addEventListener('beforeunload', () => {
      setResultData({});
    });
    window.addEventListener('unload', () => {
      setResultData({});
    });

    return () => {
      scanner?.callbacks.pageClose();

      window.removeEventListener('beforeunload', () => {
        setResultData({});
      });
      window.removeEventListener('unload', () => {
        setResultData({});
      });
    };
  }, [setResultData]);

  useEffect(() => {
    // setDisplay(false);
    let current;

    const observer = new MutationObserver(() => {
      const getOcrData = document.querySelector('#ocrResultData').dataset.result || 'init';
      const getOcrResultData =
        document.querySelector('#ocrResultData').dataset.resultHeader !== ''
          ? JSON.parse(document.querySelector('#ocrResultData').dataset.resultHeader)
          : 'init';

      if (
        getOcrData &&
        getOcrData.trim() !== '' &&
        getOcrData.trim() !== 'null' &&
        getOcrData.trim() !== 'init' &&
        getOcrData.trim() !== 'reset'
      ) {
        setResultData(JSON.parse(getOcrData));
        document.querySelector('#ocrResultData').dataset.result = 'reset';
      }

      if (
        getOcrData.trim() === '' ||
        !getOcrData ||
        (getOcrData.trim() !== 'init' && getOcrResultData.rsltCode !== '0000' && getOcrData.trim() !== 'reset')
      ) {
        if (getOcrResultData.rsltCode === '0011' && getOcrResultData.rsltCode === '0056') {
          setResultMessage(getOcrResultData.rsltMsgeCntn);
          setDisplay(true);
        } else {
          setDisplay(true);
        }
      }
    });

    if (childrenRef.current) {
      observer.observe(childrenRef.current, {
        childList: true,
        attributes: true,
        subtree: true
      });
      current = childrenRef.current;
    }

    return () => {
      if (current) {
        observer.disconnect(current);
      }
    };
  }, [setDisplay, setResultData, resultMessage]);

  useEffect(() => {
    const getOcrResultData =
      document.querySelector('#ocrResultData').dataset.resultHeader &&
      document.querySelector('#ocrResultData').dataset.resultHeader !== ''
        ? JSON.parse(document.querySelector('#ocrResultData').dataset.resultHeader)
        : 'init';

    const isAbleNext =
      getOcrResultData.rsltCode !== '0010' ||
      getOcrResultData.rsltCode !== '0011' ||
      getOcrResultData.rsltCode !== '0056';

    if (resultData && Object.keys(resultData).length > 0) {
      // const imgEnc = resultData.maskedCardImageEnc;
      // const encType = resultData.aes256Key.padEnd(32, '\0');
      // const decryptedText = decrypt(imgEnc, encType);
      // console.log(decryptedText);

      if (resultData.cardType === '1' && !display && isAbleNext) {
        navigate('/step4');
      } else if (resultData.cardType === '2' && !display && isAbleNext) {
        navigate('/step5');
      } else {
        setDisplay(true);
      }
    }
  }, [resultData, navigate, display, setDisplay]);

  const reStart = () => {
    if (display) {
      setDisplay(false);
    }
    scanner?.callbacks.reShoot();
  };

  const goHome = () => {
    setDisplay(false);
    navigate('/');
  };

  useEffect(() => {
    const getToken = sessionStorage.getItem(RivConstant.ACCESS_TOKEN_KEY);

    if (!getToken || getToken.trim().length < 1) {
      setOpenModal(true);
    }
  }, []);

  const handleModalClose = () => {
    // clear data
    sessionStorage.removeItem(RivConstant.ACCESS_TOKEN_KEY);
    setResultData({});

    setOpenModal(false);
    navigate('/');
  };

  return (
    <>
      <BgRoot id="scanContainer" className="scan-container">
        <Maskimg>
          <CameraVideo
            id="scanView"
            className="scan-view"
            playsInline
            muted
            style={{ width: '100%', height: '100%' }}
          ></CameraVideo>

          <div id="scanBoxMask" className=""></div>
          <div id="scanBoxGuide" className="">
            <div id="scanBoxLoading" className=""></div>
            <div id="scanBoxPassportPhoto" className=""></div>
            <div id="scanBoxPassportMrz" className=""></div>
            <TextTitle id="scanBoxDesc">
              <P>표시된 영역에 신분증을 맞춰 배치 후</P>
              <P>하단 버튼을 누르면 촬영됩니다.</P>
            </TextTitle>
          </div>
          <button id="autoCameraToggle" className="" style={{ display: 'none' }}></button>

          {/* <CameraBox className="scan-box-wrap"> */}
          <CameraCanvas id="scanBoxMask" style={{ display: 'none' }} />
          {/* </CameraBox> */}

          <div id="camera-btn_layout">
            <ButtonCameraIcon id="takeCameraBtn" className="camera-btn__take" src={cameraBtn} alt="Camera Action" />
          </div>
          <OcrReadFile id="file-capture-manual" type="file" name="files" capture="camera" accept="image/jpeg" />
        </Maskimg>
      </BgRoot>
      <OcrResult id="resultContainer" className="">
        <p id="resultBoxDesc" className=""></p>
        <canvas id="resultCanvas" className=""></canvas>
        <button id="resetBtn" className=""></button>
        <button id="redetectBtn" className=""></button>
        <button id="sendServerBtn" className=""></button>
      </OcrResult>
      <OcrReadReault id="ocrResultData" ref={childrenRef}></OcrReadReault>
      {display && (
        <Dialog
          title="신분증 인식 실패"
          description={resultMessage}
          buttonContents={[
            {
              text: '홈으로',
              fill: 'neutral1',
              onClick: goHome
            },
            {
              text: '재촬영',
              fill: 'primary',
              onClick: reStart
            }
          ]}
        />
      )}

      {openModal && (
        <Dialog
          title="에러 발생"
          description="에러가 발생했습니다. 다시 시도해주세요."
          buttonContents={[
            {
              text: '확인',
              fill: 'primary',
              onClick: handleModalClose
            }
          ]}
        />
      )}
    </>
  );
}
