import styled from '@emotion/styled';

const BackBar = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  background-color: var(--color-grayscale-gray-200);
`;

const Progress = styled.div`
  position: absolute;
  height: 100%;
  top: 0%;
  right: 80%;
  bottom: 0%;
  left: 0%;
  background-color: #ffd78a;
  width: ${({ step }) => {
    switch (step) {
      case 'step1':
        return '20%';
      case 'step2':
        return '40%';
      case 'step3':
        return '60%';
      case 'step4':
        return '80%';
      case 'step5':
        return '100%';
      default:
        return '0';
    }
  }};
`;

const ProgressbarRoot = styled.div`
  width: 100%;
  position: relative;
  height: 2px;
`;

function ProgressBar({ step }) {
  return (
    <ProgressbarRoot>
      <BackBar />
      <Progress step={step} />
    </ProgressbarRoot>
  );
}

export default ProgressBar;
