import styled from '@emotion/styled';

const FieldSetBox = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--body1-size);
  color: var(--text-neutral-01);
  font-family: var(--body1);
  margin-top: var(--spacing-300);
`;

const Label = styled.label`
  position: relative;
  line-height: 150%;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: var(--body2-size);
  color: var(--text-primary);
  font-family: var(--body2);
  margin-bottom: var(--spacing-050);
`;

const TextInputBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  column-gap: 8px;
`;

const TextInput = styled.input`
  width: 100%;
  position: relative;
  border-radius: var(--radius-m);
  border: 1px solid var(--border-primary);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--spacing-200);
  text-align: left;
  font-size: var(--body1-size);
  color: var(--text-primary);
  font-family: var(--body1);

  &:read-only {
    background-color: var(--bg-secondary);
  }
`;

function FieldSet({ label, value, value2, type, placeholder, multi, readonly }) {
  if (multi) {
    return (
      <FieldSetBox>
        <Label>{label}</Label>
        <TextInputBox>
          <TextInput type={type} value={value} placeholder={placeholder} readOnly />
          <TextInput type={type} value={value2} placeholder={placeholder} readOnly />
        </TextInputBox>
      </FieldSetBox>
    );
  } else {
    return (
      <FieldSetBox>
        <Label>{label}</Label>
        <TextInputBox>
          <TextInput type={type} value={value} placeholder={placeholder} readOnly />
        </TextInputBox>
      </FieldSetBox>
    );
  }
}

export default FieldSet;
