// react
import React, { useEffect, useState } from 'react';
// libraries
import { useNavigate } from 'react-router';

// components
import Dialog from 'src/components/Dialog';
import Button from 'src/components/Button';
import Header from 'src/components/Header';
import TextTitle from 'src/components/TextTitle';
import ButtonGroup from 'src/components/ButtonGroup';
// style
import styled from '@emotion/styled';
import styles from 'src/assets/app.module.css';
// assets
import idCard from 'src/assets/illustrator/id-card.svg';
import { RivConstant } from 'src/constants/rivConstant';

const ImgBox = styled.div`
  text-align: center;
  margin-top: 88px;
`;

const TextList = styled.ul`
  margin-top: 8px;
  font-size: 16px;
  line-height: 1.5;
  font-family: HanwhaGothic;
  color: #737c85;
  text-align: left;
  white-space: pre-line;
  list-style: none;
  margin-top: 16px;

  li {
    position: relative;
    padding-left: 10px;
    font-size: var(--caption-size);
    line-height: 140%;
    font-family: var(--caption);
    color: var(--text-inactive);

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);

      display: inline-block;
      width: var(--icon-size-bullet);
      height: var(--icon-size-bullet);
      background-color: #8c959d;
    }
  }
`;

export default function Step2() {
  // navigate
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  /**-------------------------------- 이벤트 헨들러 --------------------------------------*/
  // 촬영 버튼 클릭이벤트
  const handleNextClick = () => {
    navigate('/step3');
  };

  // 취소 버튼 클릭이벤트
  const handleCancelClick = () => {
    navigate('/');
    // TODO: native 적용 시 결과 값 return 으로 변경
    // 취소 결과 값 전송
    //window.ReactNativeWebView.postMessage('9999');
  };

  useEffect(() => {
    const getToken = sessionStorage.getItem(RivConstant.ACCESS_TOKEN_KEY);

    if (!getToken || getToken.trim().length < 1) {
      setOpenModal(true);
    }
  }, []);

  const handleModalClose = () => {
    // clear data
    sessionStorage.removeItem(RivConstant.ACCESS_TOKEN_KEY);

    setOpenModal(false);
    navigate('/');
  };

  return (
    <>
      <Header step="step1" />

      <div className={styles.ContWrap}>
        <TextTitle
          size="xlarge"
          type="title"
          title="신분증 촬영"
          description={`주민등록증 또는 운전면허증을 준비해 주세요.`}
        />

        <ImgBox>
          <img src={idCard} className={styles.step2_img} alt="주민등록증 샘플 이미지" />

          <TextList>
            <li>신분증을 어두운 바닥에 두고 가이드에 맞춰 촬영해주세요.</li>
            <li>빛이 반사되지 않도록 주의하세요</li>
          </TextList>
        </ImgBox>
      </div>

      <ButtonGroup gap="true" main>
        <Button text="취소" fill="neutral1" size="large" status="active" onClick={handleCancelClick} />
        <Button text="촬영" fill="primary" size="large" status="active" onClick={handleNextClick} />
      </ButtonGroup>

      {openModal && (
        <Dialog
          title="에러 발생"
          description="에러가 발생했습니다. 다시 시도해주세요."
          buttonContents={[
            {
              text: '확인',
              fill: 'primary',
              onClick: handleModalClose
            }
          ]}
        />
      )}
    </>
  );
}
