import endpoint from 'src/apis/endpoint';
import axiosInstance from 'src/apis/axios';

/*토큰 발급 API*/
export const getTokenApi = async (data) => {
  return await axiosInstance.post(endpoint.tokenGet, data).then((response) => response.data);
};

/*토큰 검증 API*/
export const validateTokenApi = async (data) => {
  return await axiosInstance.post(endpoint.token, data).then((response) => response.data);
};
